import PermissionEnum from "~/models/PermissionEnum";
import AccessGroupCustomerEnum from "~/models/AccessGroupCustomerEnum";
import DtoObject from "~/models/DtoObject";

export default class PortalUserPermissionDto extends DtoObject<PortalUserPermissionDto> {
  accessGroup: AccessGroupCustomerEnum = AccessGroupCustomerEnum.Store;
  permission: PermissionEnum = PermissionEnum.None;

  constructor(
    portalUserPermissionDtoPart: Partial<PortalUserPermissionDto> = {},
  ) {
    super();
    Object.assign(this, portalUserPermissionDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new PortalUserPermissionDto({
      accessGroup: AccessGroupCustomerEnum.Store,
      permission: PermissionEnum.None,
    });
  }

  static testValuesFactorySomeNull() {
    return new PortalUserPermissionDto({
      accessGroup: AccessGroupCustomerEnum.Store,
      permission: PermissionEnum.None,
    });
  }

  static testValuesFactoryNoneNull() {
    return new PortalUserPermissionDto({
      accessGroup: AccessGroupCustomerEnum.Store,
      permission: PermissionEnum.None,
    });
  }
}
