import axios, { HttpStatusCode, type ResponseType } from "axios";
import { type AnybillResult, AnybillResultHelper } from "~/additionalModels/AnybillResponse";
import ApiConfig from "~/config/ApiConfig";
import ModelKeyConverter from "~/utils/ModelKeyConverter";
import { useAuthModule } from "~/stores/auth";

export function useBaseService() {
  function getHeaders(token: string) {
    return {
      "content-type": "application/json",
      "authorization": `Bearer ${token}`,
    };
  }

  function getUrl(path: string) {
    return ApiConfig.baseUrl + path;
  }

  function handleError<T>(
    errors: any[],
    statusCode: number,
  ): AnybillResult<T> {
    // if (statusCode === HttpStatusCode.Unauthorized)
    //   useAuthModule().logout();
    if (errors.length === 0) {
      return AnybillResultHelper.failure(null, statusCode, null);
    }
    else {
      return AnybillResultHelper.failure(
        errors[0]?.message,
        statusCode,
        errors[0]?.code,
      );
    }
  }

  async function get<R>(path: string, token: string, responseType: ResponseType = "json", maxRedirects: number = 5): Promise<AnybillResult<R>> {
    try {
      const response = await axios.get(getUrl(path), {
        headers: getHeaders(token),
        responseType,
        maxRedirects,
      });
      return AnybillResultHelper.success(ModelKeyConverter.keyToLowerCamel(response.data), response.status);
    }
    catch (error: any) {
      if (
        error.response.status === 302
        && error.response.headers.location != null
      ) {
        return AnybillResultHelper.success(
          error.response.headers.location,
          error.response.status,
        );
      }
      // ErrorHandler.Instance.handleAxiosError(error, "baseservice get");
      return handleError(
        error.response.data.errors ?? [],
        error.response.status,
      );
    }
  }

  /**
   * Get a file as a array buffer
   */
  async function getFile(path: string, token: string): Promise<AnybillResult<any>> {
    try {
      const response = await axios.get(getUrl(path), {
        headers: getHeaders(token),
        responseType: "arraybuffer",
      });
      return AnybillResultHelper.success(response.data, response.status);
    }
    catch (error: any) {
      // ErrorHandler.Instance.handleAxiosError(error, "BaseService: GET_FILE");
      return handleError(
        error.response.data.errors ?? [],
        error.response.status,
      );
    }
  }

  async function post<T, R>(path: string, data: T, token: string, customHeader?: any): Promise<AnybillResult<R>> {
    const origHeaders = getHeaders(token);
    const headers = { "content-type": origHeaders["content-type"], "authorization": origHeaders.authorization };
    if (customHeader)
      Object.assign(headers, customHeader);

    try {
      const response = await axios.post(getUrl(path), data, { headers });
      return AnybillResultHelper.success(ModelKeyConverter.keyToLowerCamel(response.data), response.status);
    }
    catch (error: any) {
      // ErrorHandler.Instance.handleAxiosError(error, "baseservice post");

      return handleError(
        error.response.data.errors ?? [],
        error.response.status,
      );
    }
  }

  async function postFile<R>(path: string, file: File, token: string, _params?: {
    dto: any;
  }): Promise<AnybillResult<R>> {
    try {
      const formData = new FormData();
      formData.append("file", file);
      if (_params?.dto)
        formData.append("dto", _params.dto);

      const response = await axios.post(getUrl(path), formData, {
        headers: { "content-type": "multipart/form-data", "authorization": `Bearer ${token}` },
      });

      return AnybillResultHelper.success(ModelKeyConverter.keyToLowerCamel(response.data), response.status);
    }
    catch (error: any) {
      // ErrorHandler.Instance.handleAxiosError(error, "baseservice postFile");
      return handleError(
        error.response.data.errors ?? [],
        error.response.status,
      );
    }
  }

  async function put<T, R>(path: string, data: T, token: string): Promise<AnybillResult<R>> {
    try {
      const response = await axios.put(getUrl(path), data, {
        headers: getHeaders(token),
      });
      return AnybillResultHelper.success(ModelKeyConverter.keyToLowerCamel(response.data), response.status);
    }
    catch (error: any) {
      // ErrorHandler.Instance.handleAxiosError(error, "baseservice put");
      return handleError(
        error.response.data.errors ?? [],
        error.response.status,
      );
    }
  }

  async function del<R>(path: string, token: string): Promise<AnybillResult<R>> {
    try {
      const response = await axios.delete(getUrl(path), {
        headers: getHeaders(token),
      });
      return AnybillResultHelper.success(ModelKeyConverter.keyToLowerCamel(response.data), response.status);
    }
    catch (error: any) {
      // ErrorHandler.Instance.handleAxiosError(error, "baseservice delete");
      return handleError(
        error.response.data.errors ?? [],
        error.response.status,
      );
    }
  }

  return {
    get,
    getFile,
    post,
    postFile,
    put,
    del,
  };
}
