import DtoObject from "~/models/DtoObject";

export default class BillingAddressDto extends DtoObject<BillingAddressDto> {
  /**
   * @description Country of the address.
   */
  country: string = "Deutschland";
  /**
   * @description The country code of the address in ISO 3166-1 alpha-3 format.
   */
  countryCode: string | null = null;
  /**
   * @description Zip code / postal code of the address.
   */
  zip: string = "";
  /**
   * @description City of the address.
   */
  city: string = "";
  /**
   * @description Street name of the address
   */
  street: string = "";
  /**
   * @description Number / house number of the address.
   */
  number: string | null = null;
  email: string | null = null;
  /**
   * @description Name of the person/organization for a billing address
   */
  name: string | null = null;

  constructor(billingAddressDtoPart: Partial<BillingAddressDto> = {}) {
    super();
    Object.assign(this, billingAddressDtoPart);
  }

  get isEmpty() {
    return !this.zip || !this.city || !this.street || !this.number;
  }

  toJSON() {
    return {
      country: this.country,
      countryCode: this.countryCode,
      zip: this.zip,
      city: this.city,
      street: this.street,
      number: this.number,
      email: this.email,
      name: this.name,
    };
  }

  static testValuesFactoryAllNull() {
    return new BillingAddressDto({
      country: "Deutschland",
      countryCode: null,
      zip: "sRcTvdhVebLbNKiNNbiKPpHFlFKExfYJ",
      city: "evEXxpPNMYHokvLfLgbxRoAGyoRUKrYV",
      street: "muOMgiGrXjuFqHyYoGIPlxfgvtiemSJz",
      number: null,
      email: null,
      name: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new BillingAddressDto({
      country: "Deutschland",
      countryCode: null,
      zip: "WyGAtppuedFdyymEAwbRUwlZILuENsLJ",
      city: "KCQgehETxXyTqtQghrHryOlgwkeRsTcI",
      street: "lTYqZjgYvaXKlQLCbcMFiqwfYJIlrDLB",
      number: "acpykWYgtgLhBGfQOsgGnzhrFutQUokG",
      email: null,
      name: "USTFsYfLIYkfKBpDNkusuQggowyIRZxA",
    });
  }

  static testValuesFactoryNoneNull() {
    return new BillingAddressDto({
      country: "Deutschland",
      countryCode: "JROUfXHauGmjIsGyXprWTYiuPndACaAl",
      zip: "aziFsHkluPzvGvjeonFVUrlUuCRRoFMZ",
      city: "GCbDkpoYkRouGNOaeZXcOWykcLqPNVKK",
      street: "OcXnwPLqHOdlLvHFGbexnpUFtxDnMQxJ",
      number: "EojdfeOTkepXliUWgftoTwyMAOagLavf",
      email: "HsLPXBBNqQLxQBZVGFWsTfrBkCDOMVrk",
      name: "UpvwcNtFgLNuZtoMoLqIbGbFYfeIdyvr",
    });
  }
}
