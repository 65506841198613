/* eslint-disable jsdoc/check-param-names */
import { ErrorLevel } from "~/models/enum/ErrorLevel";

/**
 * Analytics class for tracking the user in the partner page.
 */
export default class AnybillAnalytics {
  private static _instance: AnybillAnalytics;

  /**
   * Returns an instance of the class.
   */
  static get Instance(): AnybillAnalytics {
    if (AnybillAnalytics._instance === undefined)
      AnybillAnalytics._instance = new AnybillAnalytics();

    return AnybillAnalytics._instance;
  }

  /**
   * Send a generic event to the analytics service in our anybill backend.
   *
   * @param options Optional named parameter map that includes [information],
   *                a map of key/value strings with additional data
   */
  async sendEvent(options: {
    information: Map<string, string>;
  }): Promise<void> {
    const parameterInformation = options.information;
    let body: string = "";
    try {
      body = JSON.stringify(Object.fromEntries(parameterInformation));

      // TODO: deactivated until analytics is built in
      // await $fetch("/api/analyticsGenericService/post", {
      //   method: "POST",
      //   body,
      // });
    }
    catch (error) {
      AnybillLogger.instance.error(
        "Something went wrong during the sending of analytical data during [sendEvent]",
        error instanceof Error ? error : new Error("Something went wrong during the sending of analytical data during [sendEvent]"),
        "AnybillAnalytics",
      );
    }
  }

  /**
   * Send a screen event to the analytics service in our anybill backend.
   *
   * This is used when changing a route or opening a page that we want to track.
   * If you want to track more information, use {@link AnybillAnalytics.sendEvent}.
   *
   *
   * @param fromRoute Identifier of the current screen
   * @param toRoute Identifier of the next screen
   */
  async sendScreenEvent(fromRoute: string, toRoute: string): Promise<void> {
    // This checks that the user is moving in the same route, therefore we don't need to track that
    if (!fromRoute || !toRoute || fromRoute === toRoute)
      return;

    try {
      // TODO: [CE-447] Extend this with fromScreenId and toScreenId for better understanding the user flow.
      // await useTypedFetch("/api/analyticsScreenService/post", {
      //   body: {
      //     screenId: toRoute.replace(/\//g, ""),
      //   },
      // });
    }
    catch (error) {
      await AnybillLogger.instance.error(
        "Something went wrong during the sending of analytical data during [sendScreenEvent]",
        error instanceof Error ? error : new Error("Something went wrong during the sending of analytical data during [sendScreenEvent]"),
        "AnybillAnalytics",
      );
    }
  }
}
