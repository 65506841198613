// import DistributorCustomerOnboardingStateDto from "~/models/DistributorCustomerOnboardingStateDto";
import DtoObject from "~/models/DtoObject";
import DistributorCustomerOnboardingStateEnum from "~/models/DistributorCustomerOnboardingStateEnum";

export default class DistributorCustomerDto extends DtoObject<
    DistributorCustomerDto
> {
  name: string | null = null;
  description: string | null = null;
  isAffiliate: boolean = false;
  isActivated: boolean = false;
  primaryColor: string | null = null;
  secondaryColor: string | null = null;
  onboardingState: DistributorCustomerOnboardingStateEnum = DistributorCustomerOnboardingStateEnum.Onboarded;

  constructor(
    distributorCustomerDtoPart: Partial<DistributorCustomerDto> = {},
  ) {
    super();
    Object.assign(this, distributorCustomerDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new DistributorCustomerDto({
      name: null,
      description: null,
      isAffiliate: false,
      isActivated: false,
      primaryColor: null,
      secondaryColor: null,
      onboardingState: DistributorCustomerOnboardingStateEnum.Onboarded,
    });
  }

  static testValuesFactorySomeNull() {
    return new DistributorCustomerDto({
      name: "HkoaUzurWXbhjBZkYNdPFPPLelQxyymZ",
      description: "hCEPgUuFoOXehwYfxlFGgYWKQhFPkTOC",
      isAffiliate: false,
      isActivated: false,
      primaryColor: null,
      secondaryColor: null,
      onboardingState: DistributorCustomerOnboardingStateEnum.Onboarded,
    });
  }

  static testValuesFactoryNoneNull() {
    return new DistributorCustomerDto({
      name: "znIODGgyukdlXHyBgqaFQilACRSInhtL",
      description: "CUurbmkaKXLLFeYrVCSmDiYxpdEwdTwn",
      isAffiliate: false,
      isActivated: false,
      primaryColor: "JqXZhFodLxbqgXvryYNESTZHMgygktlw",
      secondaryColor: "TfIFOgErKXZRaUtFPrimqkWByYcQVnkj",
      onboardingState: DistributorCustomerOnboardingStateEnum.Onboarded,
    });
  }
}
