import validate from "/app/anybill-partner-portal-v3/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/anybill-partner-portal-v3/middleware/auth.global.ts";
import route_45tracking_45global from "/app/anybill-partner-portal-v3/middleware/route_tracking.global.ts";
import manifest_45route_45rule from "/app/anybill-partner-portal-v3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  route_45tracking_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}