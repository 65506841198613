<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});

const mounted = ref(false);

onMounted(() => mounted.value = true);

function handleError() {
  if (props.error?.statusCode === 500) {
    const authCookie = useCookie("auth");
    authCookie.value = null;
    useAuthModule().logout();
  }

  clearError({ redirect: "/" });
}
</script>

<template>
  <div
    v-if="mounted"
    class="error-page"
  >
    <div class="error-content">
      <!-- Logo Section -->
      <img
        src="/public/anybill-logo.svg"
        class="error-logo"
      >

      <!-- Error Code -->
      <h1 class="error-code">
        {{ error?.statusCode || 'Error' }}
      </h1>

      <!-- Error Description -->
      <p class="error-description">
        {{ error?.message || 'Something went wrong' }}
      </p>

      <AnybillButton @click="handleError">
        {{ error?.statusCode === 500 ? $t("pages.errorPage.goBackToLogin") : $t("pages.errorPage.goBack") }}
      </AnybillButton>
    </div>
  </div>
</template>

<style scoped>
.error-page {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-content {
  text-align: center;
  max-width: 500px;
  width: 80%;
  padding: 50px 0;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  /* Soft shadow */
}

.error-logo {
  height: 70px;
  margin-bottom: 30px;
}

.error-code {
  font-size: 72px;
  font-weight: bold;
  margin-bottom: 10px;
}

.error-description {
  font-size: 18px;
  color: #555;
  /* Neutral text */
  margin-bottom: 30px;
}

.error-home-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4a90e2;
  /* Blue button */
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.error-home-link:hover {
  background-color: #357abD;
  /* Darker blue on hover */
}
</style>
