import DtoObject from "~/models/DtoObject";

export default class CorporateIdentityDto extends DtoObject<CorporateIdentityDto> {
  primaryColor: string | null = null;
  secondaryColor: string | null = null;

  constructor(corporateIdentityDtoPart: Partial<CorporateIdentityDto> = {}) {
    super();
    Object.assign(this, corporateIdentityDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new CorporateIdentityDto({
      primaryColor: null,
      secondaryColor: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new CorporateIdentityDto({
      primaryColor: null,
      secondaryColor: null,
    });
  }

  static testValuesFactoryNoneNull() {
    return new CorporateIdentityDto({
      primaryColor: "HdCozhSNbraYpkVyjnWUcIBgyiItuWOo",
      secondaryColor: "FkGJIIEQyPUEgwbqwbIjsVHiXaFxJyHs",
    });
  }
}
