<script setup lang="ts">
import { useAttrs } from "vue";

const props = defineProps({
  color: { type: String, default: "primary" },
  icon: { type: String, default: "" },
  loading: { type: Boolean, default: false },
});

const attrs = useAttrs();
const localColor = computed(() => {
  if (attrs.variant === "outlined") { return "#0F1311"; }

  if (attrs.variant === "text") { return "#000"; }

  return props.color;
},
);

const textColor = computed(() => {
  let c = "text-white";
  if (attrs.variant === "outlined") { c = "text-black"; }

  if (attrs.variant === "text") { c = "text-black"; }

  return c;
},
);
</script>

<template>
  <v-btn
    v-bind="$attrs"
    rounded
    nuxt
    class="px-6 text-white"
    :disabled="props.loading"
    variant="elevated"
    :color="localColor"
    :style="textColor"
  >
    <v-progress-circular
      v-if="props.loading"
      class="py-1"
      indeterminate
    />
    <div
      v-else
      class="d-flex align-center"
    >
      <v-icon
        v-if="icon"
        dark
        class="ml-n3 mr-2"
      >
        {{ icon }}
      </v-icon>
      <slot />
    </div>
  </v-btn>
</template>

<style lang="scss">
</style>
