import DtoObject from "~/models/DtoObject";
import PosSoftwareCustomerOnboardingStateEnum from "~/models/PosSoftwareCustomerOnboardingStateEnum";

export default class PosSoftwareCustomerDto extends DtoObject<
    PosSoftwareCustomerDto
> {
  name: string | null = null;
  description: string | null = null;
  isAffiliate: boolean = false;
  isActivated: boolean = false;
  primaryColor: string | null = null;
  secondaryColor: string | null = null;
  affiliateLink: string | null = null;
  clientId: string | null = null;
  onboardingState: PosSoftwareCustomerOnboardingStateEnum = PosSoftwareCustomerOnboardingStateEnum.Onboarded;

  constructor(
    posSoftwareCustomerDtoPart: Partial<PosSoftwareCustomerDto> = {},
  ) {
    super();
    Object.assign(this, posSoftwareCustomerDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new PosSoftwareCustomerDto({
      name: null,
      description: null,
      isAffiliate: false,
      isActivated: false,
      primaryColor: null,
      secondaryColor: null,
      affiliateLink: null,
      clientId: null,
      onboardingState: PosSoftwareCustomerOnboardingStateEnum.Onboarded,
    });
  }

  static testValuesFactorySomeNull() {
    return new PosSoftwareCustomerDto({
      name: "OZgHBWsMjpGjhxEJODUcdQIRiCXAOLaf",
      description: "kIDoCfvZxDIwlqGRxrjJBeWzqwKVzWLz",
      isAffiliate: false,
      isActivated: false,
      primaryColor: "QUMernhCxGbZnbnWLBJthowMBdVqakCa",
      secondaryColor: null,
      affiliateLink: null,
      clientId: "NxZLUDrXybsIfnuHgthfeCWkrWOZESrN",
      onboardingState: PosSoftwareCustomerOnboardingStateEnum.Onboarded,
    });
  }

  static testValuesFactoryNoneNull() {
    return new PosSoftwareCustomerDto({
      name: "qQEyrDMwRZrPYMSmfkQBMQUYsXyRDraN",
      description: "aeFtwPzManUDKQExvfsablSwxBZewYYf",
      isAffiliate: false,
      isActivated: false,
      primaryColor: "BlviQWqUXLSrpCFjtmyVSiiZaiBtdHib",
      secondaryColor: "rpXyaZcOXhWISvmwavYQkhhHpVcTDTzU",
      affiliateLink: "JDjewvblUrbRmSGXAtvsEkmGLJyYporj",
      clientId: "mvLzCLAUvXPqSCnqgepGAZulixmOkWYS",
      onboardingState: PosSoftwareCustomerOnboardingStateEnum.Onboarded,
    });
  }
}
