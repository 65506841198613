/**
 * @description Represents the cohort the merchant is in
 */
enum CustomerSegmentEnum {
  A = "A",
  B = "B",
  C = "C",
}

export default CustomerSegmentEnum;
