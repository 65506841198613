/**
 * @description Intended billing method of this VendorCustomer

## VendorBilling
A VendorCustomer does only directly receive invoices if VendorBilling is selected.
Defaults to VendorBilling if not explicitly stated otherwise.

## PosBilling
Many POS offer a combined pricing including anybill and their POS system. In such a case PosBilling should be selected.
In that case the POS receives invoices for this customer.
 */
enum BillingTypeEnum {
  VendorBilling = "VendorBilling",
  PosBilling = "PosBilling",
}

export default BillingTypeEnum;
