/**
 * Path constants for api requests
 */
export default {

  // Auth
  LOGIN_PATH: "/user/login",
  REFRESH_TOKEN_PATH: "/user/refresh",
  GET_USER_INFO_PATH: "/user/self",

  // DISPLAY_AD
  DISPLAY_AD_PATH: "/contentarea",
  PREVIEW_DISPLAY_AD_PATH: "/contentarea/preview",

  // CONTACT_EMAIL
  SEND_EMAIL: "/contactemail/send",

  // COMPANY_STRUCTURE
  CS_GET_CHILDREN_PATH: "/companystructure/stores",
  COMPANY_STRUCTURE_GET_RIGHTS_PATH: "/companystructure/rights",
  COMPANY_STRUCTURE_GET_PARENT_RIGHTS_PATH: "/companystructure/parent/rights",

  // DATA_ANALYTICS
  DATA_ANALYTICS_BY_QUERY_PATH: "/v1/analytics/data",
  STATISTICS_ENV_CERT_PATH: "/statistics/envcert",

  // CUSTOMER
  CUSTOMER_POS_PATH: "/customer/pos",
  CUSTOMER_DISTRI_PATH: "/customer/distributor",
  CUSTOMER_NEWSLETTER_PATH: "/customer/newsletter-register-success",
  CUSTOMER_VENDOR_APPLY_PATH: "/customer/vendor/apply",
  CUSTOMER_VENDOR_ACTIVATE_PATH_PREPEND: "/customer/vendor/",
  CUSTOMER_VENDOR_ACTIVATE_PATH_APPEND: "/activate",

  // CUSTOMER MANAGEMENT
  DISTRIBUTOR_CUSTOMER_PATH: "/distributorcustomer/customer",
  DISTRIBUTOR_CUSTOMER_INVITE_PATH: "/distributorcustomer/customer/invite",
  DISTRIBUTOR_INVITED_PATH: "/distributorcustomer/customer/invited",

  POS_CUSTOMER_PATH: "/possoftwarecustomer/customer",
  POS_CUSTOMER_INVITED_PATH: "/possoftwarecustomer/customer/invited",
  POS_CUSTOMER_DISTRI_INVITE_PATH: "/possoftwarecustomer/distributor/invite",

  // CUSTOMER MODULES
  CUSTOMER_MODULES_PATH: "/customermodules",

  // VENDOR CUSTOMER
  VENDOR_CUSTOMER_PREPEND_PATH: "/vendorcustomer/",
  VENDOR_CUSTOMER_STORES_APPEND_PATH: "/store",
  VENDOR_CUSTOMER_API_USERS_APPEND_PATH: "/vendorusers",
  VENDOR_CUSTOMER_ICON_APPEND_PATH: "/icon",

};
