import type ContactPersonDto from "~/models/ContactPersonDto";
import DtoObject from "~/models/DtoObject";

export default class VendorCustomerDistributorDto extends DtoObject<
    VendorCustomerDistributorDto
> {
  id: string = "";
  name: string | null = null;
  contactPersons: Array<ContactPersonDto> | null = null;

  constructor(
        vendorCustomerDistributorDtoPart: Partial<
            VendorCustomerDistributorDto
        > = {},
  ) {
    super();
    Object.assign(this, vendorCustomerDistributorDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new VendorCustomerDistributorDto({
      id: "tSDqfZEWYBeErVuRyEGHTvHrBeowPhBp",
      name: null,
      contactPersons: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new VendorCustomerDistributorDto({
      id: "tLYnfgtoffVgIxjxvUAawKnqVCjbUFey",
      name: null,
      contactPersons: [],
    });
  }

  static testValuesFactoryNoneNull() {
    return new VendorCustomerDistributorDto({
      id: "COfnTruvQidyYnblrhPpoZYvQEdOxFOt",
      name: "aHNBCSrAJoERjxOgtpDNZGXnulSHPAmK",
      contactPersons: [],
    });
  }
}
