import VendorCustomerStateEnum from "~/models/VendorCustomerStateEnum";
import VendorCustomerOnboardingStateEnum from "~/models/VendorCustomerOnboardingStateEnum";
import DtoObject from "~/models/DtoObject";

export default class VendorCustomerDto extends DtoObject<VendorCustomerDto> {
  name: string | null = null;
  description: string | null = null;
  isAffiliate: boolean = false;
  isActivated: boolean = false;
  /**
   * @description Primary color for this Customer. Hex encoded value e.g. #2596be00
   */
  primaryColor: string | null = null;
  /**
   * @description Secondary color for this Customer. Hex encoded value e.g. #2596be00
   */
  secondaryColor: string | null = null;
  /**
   * @description Saves the first time a Portal User was registered for this customer
   */
  registeredAt: string = "";
  isFranchise: boolean = false;
  /**
   * @description Email of the customer.
   */
  email: string | null = null;
  endOfFreePeriod: string | null = null;
  isFreePricing: boolean = false;
  onboardingState: VendorCustomerOnboardingStateEnum
    = VendorCustomerOnboardingStateEnum.RegistrationToSend;

  state: VendorCustomerStateEnum
    = VendorCustomerStateEnum.CreateApiCredentials;

  constructor(vendorCustomerDtoPart: Partial<VendorCustomerDto> = {}) {
    super();
    Object.assign(this, vendorCustomerDtoPart);
  }

  toJSON() {
    return {
      name: this.name,
      description: this.description,
      isAffiliate: this.isAffiliate,
      isActivated: this.isActivated,
      primaryColor: this.primaryColor,
      secondaryColor: this.secondaryColor,
      registeredAt: this.registeredAt,
      isFranchise: this.isFranchise,
      email: this.email,
      endOfFreePeriod: this.endOfFreePeriod,
      isFreePricing: this.isFreePricing,
      onboardingState: this.onboardingState,
      state: this.state,
    };
  }

  static testValuesFactoryAllNull() {
    return new VendorCustomerDto({
      name: null,
      description: null,
      isAffiliate: false,
      isActivated: false,
      primaryColor: null,
      secondaryColor: null,
      registeredAt: "SzMgIueoCvKoqaPxcSoJTLQwOuevwbwj",
      isFranchise: false,
      email: null,
      endOfFreePeriod: null,
      isFreePricing: false,
      onboardingState:
                VendorCustomerOnboardingStateEnum[
                  Object.values(VendorCustomerOnboardingStateEnum)[0]
                ],
      state: VendorCustomerStateEnum[
        Object.values(VendorCustomerStateEnum)[0]
      ],
    });
  }

  static testValuesFactorySomeNull() {
    return new VendorCustomerDto({
      name: "DRmKqpHnJmXpDQbwPaCcdVoTiAgfHvKN",
      description: "IkkAMfZQSACovCWZBXjdIxUJcOUBPQUh",
      isAffiliate: false,
      isActivated: false,
      primaryColor: "SrbbLpqLRfmZfFaPzzIUXiAlIsAaqMbj",
      secondaryColor: null,
      registeredAt: "AUbhnLRTugprSeNWVGLlsYgIfXhwlGzQ",
      isFranchise: false,
      email: "KQZuYrIyyfDJloCfrGbyVkDIHaOkyWJR",
      endOfFreePeriod: null,
      isFreePricing: false,
      onboardingState:
                VendorCustomerOnboardingStateEnum[
                  Object.values(VendorCustomerOnboardingStateEnum)[0]
                ],
      state: VendorCustomerStateEnum[
        Object.values(VendorCustomerStateEnum)[0]
      ],
    });
  }

  static testValuesFactoryNoneNull() {
    return new VendorCustomerDto({
      name: "VZoTkguogseaHddFOCQbJWDDjqywQeLm",
      description: "UKCECJxWQldrfktbZyEpudcGLnWPWYkT",
      isAffiliate: false,
      isActivated: false,
      primaryColor: "rakhhxvNWiQUyjqfWAGotZYLmIZMCczg",
      secondaryColor: "GggZTjBTtIneHwuhongRalmwNwSTddAI",
      registeredAt: "BdunJxlofNDqhXlBVqYzoBlJYORXfhee",
      isFranchise: false,
      email: "mXVijWLrLWLeMjgjHMONsOeJTDQdshPj",
      endOfFreePeriod: "dtoiKEnAApIIuPQlZKUUVXycSAXWDhrj",
      isFreePricing: false,
      onboardingState:
                VendorCustomerOnboardingStateEnum[
                  Object.values(VendorCustomerOnboardingStateEnum)[0]
                ],
      state: VendorCustomerStateEnum[
        Object.values(VendorCustomerStateEnum)[0]
      ],
    });
  }
}
