import AppConfigurationEnum from "~/models/AppConfigurationEnum";

export default class IAppConfiguration {
  discriminator: AppConfigurationEnum
    = AppConfigurationEnum.GetmyAnybillAppConfiguration;

  constructor(iAppConfigurationPart: Partial<IAppConfiguration> = {}) {
    Object.assign(this, iAppConfigurationPart);
  }

  toJSON() {
    return {
      discriminator: this.discriminator,
    };
  }

  static testValuesFactoryAllNull() {
    return new IAppConfiguration({
      discriminator:
                AppConfigurationEnum[Object.values(AppConfigurationEnum)[0]],
    });
  }

  static testValuesFactorySomeNull() {
    return new IAppConfiguration({
      discriminator:
                AppConfigurationEnum[Object.values(AppConfigurationEnum)[0]],
    });
  }

  static testValuesFactoryNoneNull() {
    return new IAppConfiguration({
      discriminator:
                AppConfigurationEnum[Object.values(AppConfigurationEnum)[0]],
    });
  }
}
