import type StoreDetailBillCreationInformationDto from "~/models/StoreDetailBillCreationInformationDto";
import type StoreOpeningHoursDto from "~/models/StoreOpeningHoursDto";
import AddressDto from "~/models/AddressDto";
import DtoObject from "~/models/DtoObject";

export default class CreateStoreDetailDto extends DtoObject<CreateStoreDetailDto> {
  displayName: string | null = null;
  legalName: string | null = null;
  address: AddressDto = new AddressDto();
  vatId: string | null = null;
  googlePlacesId: string | null = null;
  phoneNumber: string | null = null;
  supportPhoneNumber: string | null = null;
  posCount: number = 0;
  validFrom: string | null = null;
  isHidden: boolean = false;
  comingSoon: boolean | null = null;
  description: string | null = null;
  website: string | null = null;
  storeOpeningHours: Array<StoreOpeningHoursDto> | null = null;
  email: string | null = null;
  language: string | null = null;

  billCreationInformation: Array<StoreDetailBillCreationInformationDto> | null = null;

  constructor(createStoreDetailDtoPart: Partial<CreateStoreDetailDto> = {}) {
    super();
    Object.assign(this, createStoreDetailDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new CreateStoreDetailDto({
      displayName: null,
      legalName: null,
      address: new AddressDto(),
      vatId: null,
      googlePlacesId: null,
      phoneNumber: null,
      supportPhoneNumber: null,
      posCount: 79282,
      validFrom: null,
      isHidden: false,
      comingSoon: null,
      description: null,
      website: null,
      storeOpeningHours: null,
      email: null,
      billCreationInformation: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new CreateStoreDetailDto({
      displayName: null,
      legalName: null,
      address: new AddressDto(),
      vatId: null,
      googlePlacesId: "AwksIltPdgoAEARRxtuXXDGjXAngmOlA",
      phoneNumber: null,
      supportPhoneNumber: null,
      posCount: 62899,
      validFrom: null,
      isHidden: false,
      comingSoon: false,
      description: "xSnYMumqEyfTNSJRetdFQvGuCLDPsBWm",
      website: "XHHtASmoMrxSxkXqQyOKJChqqbefsZHq",
      storeOpeningHours: [],
      email: null,
      billCreationInformation: [],
    });
  }

  static testValuesFactoryNoneNull() {
    return new CreateStoreDetailDto({
      displayName: "gSxcvGincLSjlMVOPUOcjubDGYMjUPmW",
      legalName: "sapQbKBXvQWCMePefHRciGfsjMyPTNym",
      address: new AddressDto(),
      vatId: "oLpqdldhqbzXRaTDhaBePNELTTXsmjtJ",
      googlePlacesId: "yoEcJPLjUKujQJMBPWjHzvBAiDGFzUxM",
      phoneNumber: "iPTLRQFVItacDRgdJqGTLRTYjvIKaDin",
      supportPhoneNumber: "VoueEDZhVKgGfRXSgNAfkuUuqFcltZzB",
      posCount: 17748,
      validFrom: "lgznABiDgmHPfXzPUOfcwKFqJZrSZXOZ",
      isHidden: false,
      comingSoon: false,
      description: "NNMpSkSeeVWpdgHLvWgAJrajjOgsHLdG",
      website: "eFRIjeFVhLsZTeDRgyYdfsnvNAulpzcf",
      storeOpeningHours: [],
      email: "GjvHqpWPtyQrmYIxkqgvYCdJgDHgvRZY",
      billCreationInformation: [],
    });
  }
}
