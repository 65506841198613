const nodeEnvironment = process.env.NODE_ENV;
const isFullstackEnv = process.env.FULLSTACK_MODE;

export default class ApiConfig {
  static baseUrlLocal = "http://localhost:63000/api";
  static baseUrlTest = "https://partner.test.anybill.de/api";
  static baseUrlStg = "https://partner.stg.anybill.de/api";
  static baseUrlProd = "https://partner.anybill.de/api";

  static dataAnalyticsUrlTest = "https://analytics.test.anybill.de/api";
  static dataAnalyticsUrlStg = "https://analytics.stg.anybill.de/api";
  static dataAnalyticsUrlProd = "https://analytics.anybill.de/api";

  static get dataAnalyticsUrl(): string {
    const runtimeConfig = useRuntimeConfig();
    const apiEnvironment = runtimeConfig.public.apiEnvironment;

    if (apiEnvironment) {
      if (apiEnvironment === "test")
        return this.dataAnalyticsUrlTest;
      if (apiEnvironment === "staging")
        return this.dataAnalyticsUrlStg;
    }
    return this.dataAnalyticsUrlProd;
  }

  /**
   * Base URL that is changed depending on the NODE_ENV environment variable set
   * when the run config is executed.
   */
  static get baseUrl(): string {
    if (isFullstackEnv === "true")
      return this.baseUrlLocal;
    const runtimeConfig = useRuntimeConfig();
    const apiEnvironment = runtimeConfig.public.apiEnvironment;

    if (apiEnvironment) {
      if (apiEnvironment === "test")
        return this.baseUrlTest;
      if (apiEnvironment === "staging")
        return this.baseUrlStg;
      return this.baseUrlProd;
    }

    // TODO this listen to node_env, check if required
    if (nodeEnvironment === "development") {
      if (isFullstackEnv === "true")
        return this.baseUrlLocal;

      return this.baseUrlTest;
    }
    else if (nodeEnvironment === "staging") {
      return this.baseUrlStg;
    }
    else if (nodeEnvironment === "test") {
      return this.baseUrlTest;
    }

    return this.baseUrlProd;
  }
}
