import { storeToRefs } from "pinia";
import { AuthProvider } from "~/provider/AuthProvider";
import { useAuthModule } from "~/stores/auth";

export default defineNuxtRouteMiddleware(async (to, _) => {
  // Skip auth section
  if (to?.name?.toString().includes("privacy")
    || to?.name?.toString().startsWith("onboard")
    || to?.name?.toString().includes("activation")
  )
    return;

  const { authenticated, loggedInUser } = storeToRefs(useAuthModule());

  // check if value exists
  if (authenticated.value) {
    const { initialized } = storeToRefs(useUserModule());
    if (!initialized.value) {
      // initializes the user
      await AuthProvider.Instance.setUser();
      AnybillLogger.setUser(loggedInUser.value);
    }
    if (to.path === "/" || to?.name?.toString().startsWith("login"))
      return navigateTo("/dashboard");
  }

  // if authenticated doesn't exist redirect to log in
  if (!authenticated.value && !to?.name?.toString().startsWith("login")) {
    reloadNuxtApp({
      path: "/login",
    });
  }
});
