import DtoObject from "~/models/DtoObject";

export default class ContactPersonDto extends DtoObject<ContactPersonDto> {
  id: string = "";
  lastName: string | null = null;
  firstName: string | null = null;
  eMail: string | null = null;
  phone: string | null = null;
  position: string | null = null;
  department: string | null = null;

  constructor(contactPersonDtoPart: Partial<ContactPersonDto> = {}) {
    super();
    Object.assign(this, contactPersonDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new ContactPersonDto({
      id: "xptqMbkzMNpJoqqUmReydSuYQcaInCwo",
      lastName: null,
      firstName: null,
      eMail: null,
      phone: null,
      position: null,
      department: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new ContactPersonDto({
      id: "XLcTcwTbTZvjFHaTYIdTPeOGVLvVbrKj",
      lastName: "UqMKEWHwhuQOrwCASmOEtqXMbVCNtkQl",
      firstName: null,
      eMail: null,
      phone: null,
      position: null,
      department: "aWykFOOoQnHKgPpwOGiUkWrdCkNPBNjM",
    });
  }

  static testValuesFactoryNoneNull() {
    return new ContactPersonDto({
      id: "bBWfIJWyfsAKDWbGXKqLXxbIXGquKLRz",
      lastName: "dIatkQYSZpoVayTQiGEklhHJPomfhamr",
      firstName: "rcMOgLCqAJxmeStSXchNEEkyInIAqROm",
      eMail: "TinJasPipMSnxnpNzQYuiFRvKeMFUXfI",
      phone: "IdbRwVLgPKyvWGjpxRnjPPAegGeGxlph",
      position: "IKfMbqsNeONSugwAnPmVEaAfcmQPNSqw",
      department: "DXzsAjnkFgimfEbBdrfAKAqRXPOIsNhS",
    });
  }
}
