import DtoObject from "~/models/DtoObject";

export default class CustomerLegalInfoDto extends DtoObject<CustomerLegalInfoDto> {
  privacyPolicyVersion: string | null = null;
  termsOfServiceVersion: string | null = null;
  legalAgreementsIdentifier: string | null = null;

  constructor(customerLegalInfoDtoPart: Partial<CustomerLegalInfoDto> = {}) {
    super();
    Object.assign(this, customerLegalInfoDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new CustomerLegalInfoDto({
      privacyPolicyVersion: null,
      termsOfServiceVersion: null,
      legalAgreementsIdentifier: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new CustomerLegalInfoDto({
      privacyPolicyVersion: null,
      termsOfServiceVersion: "NLDIiPMAawzkoXqbpuytsYjvkNkyLfiK",
      legalAgreementsIdentifier: "xoaPclPFzpavneNbjBSpXTvEopXuhicv",
    });
  }

  static testValuesFactoryNoneNull() {
    return new CustomerLegalInfoDto({
      privacyPolicyVersion: "xZnTnTQIRiaoKTqzamELGavJuqyIeJHz",
      termsOfServiceVersion: "rKIyToZkYUuGTdaOqhNCbYSlPhRSJOxX",
      legalAgreementsIdentifier: "xgYnBKlwfYVbbXFbVwHbINoTyWgwrpzR",
    });
  }
}
