import DtoObject from "~/models/DtoObject";

export default class AddressDto extends DtoObject<AddressDto> {
  /**
   * @description Country of the address.
   */
  country: string = "Deutschland";
  /**
   * @description The country code of the address in ISO 3166-1 alpha-3 format.
   */
  countryCode: string | null = "DEU";
  /**
   * @description Zip code / postal code of the address.
   */
  zip: string = "";
  /**
   * @description City of the address.
   */
  city: string = "";
  /**
   * @description Street name of the address
   */
  street: string = "";
  /**
   * @description Number / house number of the address.
   */
  number: string | null = null;

  constructor(addressDtoPart: Partial<AddressDto> = {}) {
    super();
    Object.assign(this, addressDtoPart);
  }

  get isEmpty() {
    return !this.zip || !this.city || !this.street || !this.number;
  }

  toJSON() {
    return {
      country: this.country,
      countryCode: this.countryCode,
      zip: this.zip,
      city: this.city,
      street: this.street,
      number: this.number,
    };
  }

  static testValuesFactoryAllNull() {
    return new AddressDto({
      country: "Deutschland",
      countryCode: null,
      zip: "spQDwXyyejLpbsgtBwReHyAjeaQgRVWu",
      city: "ZktaHkyfThmvzrxvpSctmIntxmfzLydJ",
      street: "PKkoFHaXHbiIFkGDBHYRKjZpGqYJuzGT",
      number: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new AddressDto({
      country: "Deutschland",
      countryCode: null,
      zip: "qIZrIUEGpqoFyCSOZadJFLiSRHWRkieL",
      city: "lXpfrgvkIxIPNHSyeGkCpbSSxCTnKkdI",
      street: "MnWbVgpLvtJuVkmmuPYNGlnzJlLkDYnV",
      number: "POdqdqVYAAludGsvUrqssBUHLdXmJGwr",
    });
  }

  static testValuesFactoryNoneNull() {
    return new AddressDto({
      country: "Deutschland",
      countryCode: "NacGTOtqyQuAdFHNwljcuKsxrOmeDQBp",
      zip: "QQAzDnJFcOMrhrvjYhIzcweHnGxRYmUz",
      city: "fcVLTJrfyWmRsLJEfxBWzDMbrpiDxdrQ",
      street: "LSMdYhsvOuaaHzoEmFeSwRJgRnssamhK",
      number: "sgdbYYRhriCfmZxnbnXYpYOXTxBdhLFy",
    });
  }
}
