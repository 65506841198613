import type CategoryDto from "~/models/CategoryDto";
import DtoObject from "~/models/DtoObject";

export default class VendorDto extends DtoObject<VendorDto> {
  /**
   * @description Id of the vendor.
   */
  id: string = "";
  /**
   * @description Name of the vendor.
   */
  name: string | null = null;
  /**
   * @description Icon Resource of the vendor.
   */
  iconResource: string | null = null;
  /**
   * @description About us description of the vendor.
   */
  aboutUsDescription: string | null = null;
  /**
   * @description About us Image Resource of the vendor.
   */
  aboutUsImageResource: string | null = null;
  /**
   * @description Categories of the Vendor
   */
  categories: Array<CategoryDto> | null = null;
  /**
   * @description Company Email of the vendor
   */
  email: string | null = null;
  /**
   * @description Company phone number of the vendor
   */
  phoneNumber: string | null = null;
  /**
   * @description Company Homepage of the vendor
   */
  homepage: string | null = null;
  storeComingSoonDefault: boolean = false;

  constructor(vendorDtoPart: Partial<VendorDto> = {}) {
    super();
    Object.assign(this, vendorDtoPart);
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      iconResource: this.iconResource,
      aboutUsDescription: this.aboutUsDescription,
      aboutUsImageResource: this.aboutUsImageResource,
      categories: this.categories,
      email: this.email,
      phoneNumber: this.phoneNumber,
      homepage: this.homepage,
    };
  }

  static testValuesFactoryAllNull() {
    return new VendorDto({
      id: "bGFuhRcvtekoBalUoreojJkHrBbmFgBJ",
      name: null,
      iconResource: null,
      aboutUsDescription: null,
      aboutUsImageResource: null,
      categories: null,
      email: null,
      phoneNumber: null,
      homepage: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new VendorDto({
      id: "DlyEYEvZlMLmjEhbXNBaKBZGkZjraZrg",
      name: "aHNthtaXLpMwFsWLeHSiQBIMDhmhvknh",
      iconResource: null,
      aboutUsDescription: "WgxdIsqAmsmlyAUCZUGBdelPHtMMSBXH",
      aboutUsImageResource: null,
      categories: [],
      email: null,
      phoneNumber: "RTmdkvMfUuSxFQGQLROwEcMbkSgwoQyr",
      homepage: "zDIDaZszyOpoyAcNWFWmtQRzjUlYgwzE",
    });
  }

  static testValuesFactoryNoneNull() {
    return new VendorDto({
      id: "QGOPlSkLWGGlxYGwQQSlWSPkJTbzqTro",
      name: "YuQNkFpZkchJkkOkFZwKdxIWDQTsNaVn",
      iconResource: "RMLIFsLEvWUzzysFQxWzNGPZZMUjUQDW",
      aboutUsDescription: "QPAsuRkCEdxSCUbinrKsEBVxijnUvdPR",
      aboutUsImageResource: "KfDpcNMHwtTsyuDzUrpssgVtZeUopTxe",
      categories: [],
      email: "xaKSyaLqJntOElCCdPpBUAPSelmhLAOI",
      phoneNumber: "DGxJoqoytDKkbbfTIHavHqANGQQZixjC",
      homepage: "ToGVDptsbVYEzYrmufwSwsLGBhQNoWgh",
    });
  }
}
