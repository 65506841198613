import { endOfYear } from "date-fns";
import { storeToRefs } from "pinia";
import { type AnybillResult, AnybillResultHelper } from "~/additionalModels/AnybillResponse";
import ApiConfig from "~/config/ApiConfig";

import { useAuthModule } from "~/stores/auth";

export default class BaseService {
  private static _instance: BaseService;

  static get Instance(): BaseService {
    if (BaseService._instance === undefined)
      BaseService._instance = new BaseService();

    return BaseService._instance;
  }

  private static handleError<T>(
    errors: any[],
    statusCode: number,
    errorString?: string,
  ): AnybillResult<T> {
    // TODO check what error is coming in and react to it
    if (errors.length === 0) {
      if (errorString)
        return AnybillResultHelper.failure(errorString, statusCode, null);

      return AnybillResultHelper.failure(null, statusCode, null);
    }
    else {
      return AnybillResultHelper.failure(errors[0], statusCode, errors[0].code);
    }
  }

  async get<T>({
    path,
    body,
    responseType,
    headers,
    query,
    authNeeded,
    isComponentFetch = false,
  }: {
    path: string;
    body?: any;
    responseType?: "json" | "blob" | "text" | "arrayBuffer" | "stream";
    headers?: any;
    query?: any;
    authNeeded?: boolean;
    isComponentFetch?: boolean;
  }): Promise<AnybillResult<T>> {
    return this.call(
      "GET",
      path,
      body,
      responseType,
      headers,
      query,
      authNeeded,
      undefined,
      false,
      { isComponentFetch },
    );
  }

  async post<T>({
    path,
    body,
    responseType,
    headers,
    query,
    authNeeded,
    isDataAnalytics,
    isComponentFetch = false,
  }: {
    path: string;
    body?: any;
    responseType?: "json" | "blob" | "text" | "arrayBuffer" | "stream";
    headers?: any;
    query?: any;
    authNeeded?: boolean;
    isDataAnalytics?: boolean;
    isComponentFetch?: boolean;
  }): Promise<AnybillResult<T>> {
    return this.call(
      "POST",
      path,
      body,
      responseType,
      headers,
      query,
      authNeeded,
      undefined,
      isDataAnalytics,
      { isComponentFetch },
    );
  }

  async put<T>({
    path,
    body,
    responseType,
    headers,
    query,
    authNeeded,
  }: {
    path: string;
    body?: any;
    responseType?: "json" | "blob" | "text" | "arrayBuffer" | "stream";
    headers?: any;
    query?: any;
    authNeeded?: boolean;
  }): Promise<AnybillResult<T>> {
    return this.call("PUT", path, body, responseType, headers, query, authNeeded);
  }

  async delete<T>({
    path,
    body,
    responseType,
    headers,
    query,
    authNeeded,
  }: {
    path: string;
    body?: any;
    responseType?: "json" | "blob" | "text" | "arrayBuffer" | "stream";
    headers?: any;
    query?: any;
    authNeeded?: boolean;
  }): Promise<AnybillResult<T>> {
    return this.call("DELETE", path, body, responseType, headers, query, authNeeded);
  }

  private async call<T>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    path: string,
    body?: any,
    responseType: "json" | "blob" | "text" | "arrayBuffer" | "stream" = "json",
    headers?: { [key: string]: string },
    query?: any,
    authNeeded: boolean = true,
    tokenRefreshed: boolean = false, // TODO: refresh removed temporarily
    isDataAnalytics: boolean = false,
    _params?: {
      isComponentFetch: boolean;
    },
  ): Promise<AnybillResult<T>> {
    const header = headers ?? {};
    const authModule = useAuthModule();
    const { token } = storeToRefs(authModule);

    // TODO: very ugly, do with proxy
    const url = isDataAnalytics
      ? ApiConfig.dataAnalyticsUrl + path
      : ApiConfig.baseUrl + path;
    if (authNeeded) {
      if (token.value?.access_token) {
        header.Authorization = `Bearer ${token.value.access_token}`;
      }
      else {
        authModule.logout();
        return AnybillResultHelper.failure("Unauthorized", 401, null);
      }
    }

    const responseHeaders = ref<Headers>(new Headers());

    // During SSR data is fetched twice, once on the server and once on the client.
    if (_params?.isComponentFetch) {
      let responseError: Error | undefined;

      try {
        const response = await $fetch<string>(url, {
          method,
          body,
          headers: header,
          query,
          responseType,
          timeout: 30000,
          onResponse({ error, response }) {
            responseError = error;
            responseHeaders.value = response.headers;
          },
        });

        if (responseError) {
          // currently no refresh
          return BaseService.handleError([responseError], 400);
        }
        return AnybillResultHelper.success(
          response as T,
          200,
          responseHeaders.value,
        );
      }
      catch (e) {
        return BaseService.handleError([(e as any).data ?? "-", (e as any).cause], 400);
      }
    }
    else {
      // use $fetch for component calls and useFetch only for setup
      // TODO: https://www.npmjs.com/package/nuxt-cookies-auth possible solution!
      const { data, error } = await useFetch(url, {
        method,
        body,
        headers: header,
        query,
        responseType,
        timeout: 30000,
        onResponse({ request, response, options }) {
          responseHeaders.value = response.headers;
        },
      });

      if (error.value) {
        if (error.value.statusCode === 401) {
          authModule.logout();

          await AnybillLogger.instance
            .warn(
              `[${method}] Unauthorized during fetch request\n${error.value.data?.title ?? "- No title -"}\n${error.value.cause ?? "- No cause -"}`,
              error.value,
              "BaseService",
            );
          return BaseService.handleError(error.value.data?.errors ?? [], error.value.statusCode ?? 0);
        }
        if (error.value.data?.title) {
          await AnybillLogger.instance
            .error(
              `[${method}] Exception caught during fetch request\n${error.value.data?.title}\n${error.value.cause}`,
              error.value,
              "BaseService",
            );
          return BaseService.handleError(error.value.data?.errors ?? [], error.value.statusCode ?? 0, `${error.value.data?.title}\n${error.value.cause}`);
        }

        await AnybillLogger.instance
          .error(
            `[${method}] Exception caught during fetch request`,
            error.value,
            "BaseService",
          );

        return BaseService.handleError(error.value.data?.errors ?? [], error.value.statusCode ?? 0);
      }
      else if (data.value) {
        let returnData;
        // TODO: we have to fix the lower/uppercases...
        // Special case for getting files/arraybuffers from the backend. We do not lower camel case the content
        if (responseType === "arrayBuffer")
          returnData = data.value as T;
        else
          returnData = ModelKeyConverter.keyToLowerCamel(data.value) as T;

        return AnybillResultHelper.success(
          returnData,
          200,
          responseHeaders.value,
        );
      }
      else {
        return AnybillResultHelper.success(void {} as T, 204, responseHeaders.value);
      }
    }
  }
}
