import PosSoftwareCustomerDto from "~/models/PosSoftwareCustomerDto";
import UserInfoDto from "~/models/UserInfoDto";

export default class PosSoftwareCustomerUserInfoDto extends UserInfoDto {
  posSoftwareCustomer: PosSoftwareCustomerDto = new PosSoftwareCustomerDto();

  constructor(
    posSoftwareCustomerUserInfoDtoPart: Partial<PosSoftwareCustomerUserInfoDto> = {},
  ) {
    super();
    Object.assign(this, posSoftwareCustomerUserInfoDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new PosSoftwareCustomerUserInfoDto({
      posSoftwareCustomer: new PosSoftwareCustomerDto(),
    });
  }

  static testValuesFactorySomeNull() {
    return new PosSoftwareCustomerUserInfoDto({
      posSoftwareCustomer: new PosSoftwareCustomerDto(),
    });
  }

  static testValuesFactoryNoneNull() {
    return new PosSoftwareCustomerUserInfoDto({
      posSoftwareCustomer: new PosSoftwareCustomerDto(),
    });
  }
}
