import type CategoryDto from "~/models/CategoryDto";
import type { AnybillResult } from "~/additionalModels/AnybillResponse";

export const useCategoryModule = defineStore("category", () => {
  const categories = ref<CategoryDto[] | null >(null);
  const loading = ref(false);

  const initialized = computed(() => {
    return !!categories.value;
  });

  function reset() {
    setCategories(null);
  }

  async function populate() {
    setLoading(true);
    const t = await useTypedFetch<AnybillResult<Array<CategoryDto>>>("/categoryService/get");
    if (t.success)
      setCategories(t.value);

    setLoading(false);
  }

  function setLoading(isLoading: boolean) {
    loading.value = isLoading;
  }

  function setCategories(cat: CategoryDto[] | null) {
    categories.value = cat;
  }

  return {
    categories,
    loading,
    initialized,
    reset,
    populate,

  };
});
