import UserInfoDto from "./UserInfoDto";
import DistributorCustomerDto from "~/models/DistributorCustomerDto";

export default class DistributorCustomerUserInfoDto extends UserInfoDto {
  distributorCustomer: DistributorCustomerDto = new DistributorCustomerDto();

  constructor(
    distributorCustomerUserInfoDtoPart: Partial<DistributorCustomerUserInfoDto> = {},
  ) {
    super();
    Object.assign(this, distributorCustomerUserInfoDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new DistributorCustomerUserInfoDto({
      distributorCustomer: new DistributorCustomerDto(),
    });
  }

  static testValuesFactorySomeNull() {
    return new DistributorCustomerUserInfoDto({
      distributorCustomer: new DistributorCustomerDto(),
    });
  }

  static testValuesFactoryNoneNull() {
    return new DistributorCustomerUserInfoDto({
      distributorCustomer: new DistributorCustomerDto(),
    });
  }
}
