/**
 * TODO: rework this,
 * @param path
 * @param requestBody
 * @returns
 */
export async function useTypedFetch<T extends NonNullable<unknown>>(path: string, requestBody?: any): Promise<T> {
  const authModule = useAuthModule();
  const { token } = storeToRefs(authModule);
  const resp: any = await $fetch(path, { method: "POST", redirect: "error", body: { body: requestBody, token: token.value?.access_token ?? "" } });

  const res: T = {} as T;
  return Object.assign(res, resp);
}
