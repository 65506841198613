import AnybillAnalytics from "~/utils/AnybillAnalytics";

/**
 * Global middleware for tracking route changes
 *
 * TODO: [CE-447] add to/from logic here
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  const fromRouteName = from.path?.toString();
  const toRouteName = to.path?.toString();

  await AnybillAnalytics.Instance.sendScreenEvent(fromRouteName, toRouteName);
});
