import DtoObject from "~/models/DtoObject";

export default class MiscellaneousCustomerDto extends DtoObject<MiscellaneousCustomerDto> {
  name: string | null = null;
  description: string | null = null;
  isAffiliate: boolean = false;
  isActivated: boolean = false;
  primaryColor: string | null = null;
  secondaryColor: string | null = null;
  affiliateLink: string | null = null;

  constructor(
    miscellaneousCustomerDtoPart: Partial<MiscellaneousCustomerDto> = {},
  ) {
    super();
    Object.assign(this, miscellaneousCustomerDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new MiscellaneousCustomerDto({
      name: null,
      description: null,
      isAffiliate: false,
      isActivated: false,
      primaryColor: null,
      secondaryColor: null,
      affiliateLink: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new MiscellaneousCustomerDto({
      name: null,
      description: "DQWAwNnefOsDjnYxsSDkNuXVPTTTbxHr",
      isAffiliate: false,
      isActivated: false,
      primaryColor: "CNRalixrdmSTIjLvTUWfwKlUXENfzTrg",
      secondaryColor: null,
      affiliateLink: null,
    });
  }

  static testValuesFactoryNoneNull() {
    return new MiscellaneousCustomerDto({
      name: "fTdXZHQoZWLFFRoWRjifXRaRlariAmug",
      description: "fralVAdODahqmKQxDKBmNgKYIaoqGnWI",
      isAffiliate: false,
      isActivated: false,
      primaryColor: "LOJXmSOvihBpMQcrkRvKBWTglaAxbdOB",
      secondaryColor: "XEKsuNBTgItLftLWOAFRReffdzEUuzQP",
      affiliateLink: "sdmsNXjDPHjQIxbPtOVKMdTVCaxpZJNp",
    });
  }
}
