import MiscellaneousCustomerDto from "~/models/MiscellaneousCustomerDto";
import UserInfoDto from "~/models/UserInfoDto";

export default class MiscellaneousCustomerUserInfoDto extends UserInfoDto {
  miscellaneousCustomer: MiscellaneousCustomerDto = new MiscellaneousCustomerDto();

  constructor(
    miscellaneousCustomerUserInfoDtoPart: Partial<MiscellaneousCustomerUserInfoDto> = {},
  ) {
    super();
    Object.assign(this, miscellaneousCustomerUserInfoDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new MiscellaneousCustomerUserInfoDto({
      miscellaneousCustomer: new MiscellaneousCustomerDto(),
    });
  }

  static testValuesFactorySomeNull() {
    return new MiscellaneousCustomerUserInfoDto({
      miscellaneousCustomer: new MiscellaneousCustomerDto(),
    });
  }

  static testValuesFactoryNoneNull() {
    return new MiscellaneousCustomerUserInfoDto({
      miscellaneousCustomer: new MiscellaneousCustomerDto(),
    });
  }
}
