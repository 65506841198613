// import this after install `@mdi/font` package
import "@mdi/font/css/materialdesignicons.css";

import "vuetify/styles";
import { createVuetify } from "vuetify";

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    theme: {
      defaultTheme: "light",
      themes: {
        light: {
          colors: {
            primary: "#1FC795",
            secondary: "#FFFFFF",
          },
        },
        // TODO: add dark mode
      },
    },
  });
  app.vueApp.use(vuetify);
});
