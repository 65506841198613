export default class ModelKeyConverter {
  /**
   * Converts object keys to lower camel case.
   * @param {any} o - The object to convert.
   * @returns {any} - The object with keys converted to lower camel case.
   */
  static keyToLowerCamel(o: any): any {
    let newO: any, origKey, newKey, value;
    if (Array.isArray(o)) {
      return o.map((value) => {
        if (typeof value === "object")
          value = ModelKeyConverter.keyToLowerCamel(value);

        return value;
      });
    }
    else {
      newO = {};
      for (origKey in o) {
        if (Object.prototype.hasOwnProperty.call(o, origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
          value = o[origKey];
          if (value !== null && (Array.isArray(value) || value.constructor === Object || typeof value === "object"))
            value = ModelKeyConverter.keyToLowerCamel(value);

          newO[newKey] = value;
        }
      }
    }
    return newO;
  }

  /**
   * Converts object keys to upper camel case.
   * @param {any} o - The object to convert.
   * @returns {any} - The object with keys converted to upper camel case.
   */
  static keyToUpperCamel(o: any): any {
    let newO: any, origKey, newKey, value;
    if (Array.isArray(o)) {
      return o.map((value) => {
        if (typeof value === "object")
          value = ModelKeyConverter.keyToUpperCamel(value);

        return value;
      });
    }
    else {
      newO = {};
      for (origKey in o) {
        if (Object.prototype.hasOwnProperty.call(o, origKey)) {
          newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString();
          value = o[origKey];

          if (value === undefined) {
            newO[newKey] = undefined;
            continue;
          }

          if (value !== null && (Array.isArray(value) || value.constructor === Object || typeof value === "object"))
            value = ModelKeyConverter.keyToUpperCamel(value);

          newO[newKey] = value;
        }
      }
    }
    return newO;
  }
}
