import ModelKeyConverter from "~/utils/ModelKeyConverter";

export default class DtoObject<T extends DtoObject<T>> {
  /**
   * Uppercases the dto
   *
   * @returns DTO object with upper cases
   */
  toDto(): any {
    return ModelKeyConverter.keyToUpperCamel(this);
  }

  /**
   * Lowercases the dto
   *
   */
  setFromDto(dto: Partial<T>): any {
    Object.assign(this, ModelKeyConverter.keyToLowerCamel(dto));
  }
}
