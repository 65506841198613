import type ContactPersonDto from "~/models/ContactPersonDto";
import PosSoftwareCustomerStateDto from "~/models/PosSoftwareCustomerStateEnum";
import DtoObject from "~/models/DtoObject";

export default class VendorCustomerPosDto extends DtoObject<
    VendorCustomerPosDto
> {
  id: string = "";
  name: string | null = null;
  state: PosSoftwareCustomerStateDto
    = PosSoftwareCustomerStateDto.InIntegration;

  contactPersons: Array<ContactPersonDto> | null = null;

  constructor(vendorCustomerPosDtoPart: Partial<VendorCustomerPosDto> = {}) {
    super();
    Object.assign(this, vendorCustomerPosDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new VendorCustomerPosDto({
      id: "iKnHxWmWROoLReJdLNAMjKGEzKzHgonK",
      name: null,
      state: PosSoftwareCustomerStateDto.InIntegration,
      contactPersons: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new VendorCustomerPosDto({
      id: "BpwIBXNUZqyyPUamVbaZiJNIaZIgFhSJ",
      name: null,
      state: PosSoftwareCustomerStateDto.InIntegration,
      contactPersons: null,
    });
  }

  static testValuesFactoryNoneNull() {
    return new VendorCustomerPosDto({
      id: "MtwEVpGaXzsmgAMqnfKVZOQNmrUTUoRV",
      name: "mxDWmuBfJrciVCXRokZHswtTMmYUymtT",
      state: PosSoftwareCustomerStateDto.InIntegration,
      contactPersons: [],
    });
  }
}
