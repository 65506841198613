export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("app:created", (app) => {
    // handle error, e.g. report to a service
    // console.log(app);

    // this is to 'fix' the consola error that is happening with logObj.getTime in dev build
    // it will force an error: [nuxt] Failed to stringify dev server logs.
    // This will do nothing in prod, as the dev-server-logs are not called there.
    // Wait until this is fixed: https://github.com/nuxt/devtools/issues/700
    if (process.env.NODE_ENV !== "production")
      console.log(app);
  });
});
