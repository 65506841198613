import DtoObject from "~/models/DtoObject";
import IAppConfiguration from "~/models/IAppConfiguration";

export default class InstalledAppDto extends DtoObject<InstalledAppDto> {
  id: string = "";
  identifier: string | null = null;
  isActivated: boolean = false;
  configuration: IAppConfiguration = new IAppConfiguration();
  // TODO: move this to its own DTO: ContentManagementAppDto
  // TODO: EmailMarketingAppVariantDto also uses this
  variant: string = "Basic";

  // TODO: add VendorReceiptAppDto
  behavior: string = "";
  version: string = "V2";

  constructor(installedAppDtoPart: Partial<InstalledAppDto> = {}) {
    super();
    Object.assign(this, installedAppDtoPart);
  }

  toJSON() {
    return {
      id: this.id,
      identifier: this.identifier,
      isActivated: this.isActivated,
      configuration: this.configuration,
    };
  }

  static testValuesFactoryAllNull() {
    return new InstalledAppDto({
      id: "lGkqvPSqHTAlaZRBKiGdvalzJSxWrvmR",
      identifier: null,
      isActivated: false,
    });
  }

  static testValuesFactorySomeNull() {
    return new InstalledAppDto({
      id: "vuhvHZLdwgawzdweLVrNSiFSOwgteHtP",
      identifier: "RZbZMKRlqCVsVLCVreUyWGSkOYswFGfe",
      isActivated: false,
    });
  }

  static testValuesFactoryNoneNull() {
    return new InstalledAppDto({
      id: "lQvnLHKPiKYRkMvyxNWrNSSkiRSOGIUd",
      identifier: "jQwHDGWqQPquhKUmzzLdjxVKFFmIgNnL",
      isActivated: false,
    });
  }
}
