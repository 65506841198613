import { useBaseService } from "~/composables/useBaseService";
// TODO: check token handling
export async function companyIconUpload(companyIcon: File) {
  const authModule = useAuthModule();
  const { token } = storeToRefs(authModule);
  const { postFile } = useBaseService();
  const data = await postFile(`/vendor/icon`, companyIcon, token.value!.access_token!);
  return data;
}

export async function companyIconDelete() {
  const authModule = useAuthModule();
  const { token } = storeToRefs(authModule);
  const { del } = useBaseService();
  await del(`/vendor/icon`, token.value!.access_token!);
}

export async function storeIconUpload({ storeId, file }: { storeId: string | null | undefined; file: File }) {
  const authModule = useAuthModule();
  const { token } = storeToRefs(authModule);
  const { postFile } = useBaseService();

  const data = await postFile(`/store/${storeId}/icon`, file, token.value!.access_token!);
  return data;
}

export async function storeIconDelete({ storeId }: { storeId: string | null | undefined }) {
  const authModule = useAuthModule();
  const { token } = storeToRefs(authModule);
  const { del } = useBaseService();
  const data = await del(`/store/${storeId}/icon`, token.value!.access_token!);
  return data;
}
