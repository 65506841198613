enum AccessGroupCustomerEnum {
  Store = "Store",
  PortalUser = "PortalUser",
  VendorApiUser = "VendorApiUser",
  CustomerReferral = "CustomerReferral",
  PricingDetails = "PricingDetails",
  Coupons = "Coupons",
  LoyaltyCard = "LoyaltyCard",
  SdkDocs = "SdkDocs",
  VendorDocs = "VendorDocs",
  VendorNews = "VendorNews",
  VendorReceipt = "VendorReceipt",
  ContentManagement = "ContentManagement",
  EmailMarketing = "EmailMarketing",
}

export default AccessGroupCustomerEnum;
