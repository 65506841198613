import { defineStore } from "pinia";
import type { AnybillResult } from "~/additionalModels/AnybillResponse";
import type CurrentPricingOverviewDto from "~/models/CurrentPricingOverviewDto";

export const usePricingModule = defineStore("pricing", () => {
  const pricingOverview = ref<CurrentPricingOverviewDto | null>(null);
  const loading = ref(false);
  const initialized = ref(false);

  async function populate() {
    loading.value = true;
    const result = await useTypedFetch<AnybillResult<CurrentPricingOverviewDto>>(
      "/pricingReferenceStandardService/get",
    );

    loading.value = false;
    if (result.success) {
      pricingOverview.value = result.value;
      initialized.value = true;
    }
    else {
      // ErrorHandler.Instance.handleAxiosError(result.errorMessage, "PricingStore");
      initialized.value = false;
    }
  }

  return { populate, pricingOverview, loading, initialized };
});
