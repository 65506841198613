import BillingAddressDto from "./BillingAddressDto";
import MiscellaneousCustomerDto from "./MiscellaneousCustomerDto";
import type ContactPersonDto from "~/models/ContactPersonDto";
import type EffectivePermissionDto from "~/models/EffectivePermissionDto";
import type PortalUserPermissionDto from "~/models/PortalUserPermissionDto";
import CorporateIdentityDto from "~/models/CorporateIdentityDto";
import CustomerLegalInfoDto from "~/models/CustomerLegalInfoDto";
import AddressDto from "~/models/AddressDto";
import DtoObject from "~/models/DtoObject";

export default class UserInfoDto extends DtoObject<UserInfoDto> {
  type: string | null = null;
  customerId: string = "";
  userId: string = "";
  username: string | null = null;
  address: AddressDto = new AddressDto();
  billingAddress: BillingAddressDto = new BillingAddressDto();
  legal: CustomerLegalInfoDto = new CustomerLegalInfoDto();
  corporateIdentity: CorporateIdentityDto = new CorporateIdentityDto();
  permissions: Array<PortalUserPermissionDto> | null = null;
  effectivePermissions: Array<EffectivePermissionDto> | null = null;
  contactPerson: ContactPersonDto | null = null;
  miscellaneousCustomer: MiscellaneousCustomerDto = new MiscellaneousCustomerDto();
  isAdmin: boolean = false;

  constructor(userInfoDtoPart: Partial<UserInfoDto> = {}) {
    super();
    Object.assign(this, userInfoDtoPart);
  }

  toJSON() {
    return {
      type: this.type,
      customerId: this.customerId,
      userId: this.userId,
      username: this.username,
      address: this.address,
      billingAddress: this.billingAddress,
      legal: this.legal,
      corporateIdentity: this.corporateIdentity,
      permissions: this.permissions,
      effectivePermissions: this.effectivePermissions,
      isAdmin: this.isAdmin,
    };
  }

  static testValuesFactoryAllNull() {
    return new UserInfoDto({
      type: null,
      customerId: "NOizuOUIKnGDasJGMinrFRihgNZyfUeu",
      userId: "mpTIcbjXuEwwlSZrKoNtxDGYnuCVlMPw",
      username: null,
      address: new AddressDto(),
      legal: new CustomerLegalInfoDto(),
      corporateIdentity: new CorporateIdentityDto(),
      permissions: null,
      effectivePermissions: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new UserInfoDto({
      type: null,
      customerId: "GIXfsEKjUnmFzLpqfpvkOVutnNsjXDyM",
      userId: "gSqgWxsJWkGQxMTzMmMsGKmeybxEuUNz",
      username: "otuOUucDoYjjxOSQHyDWxHjahlLTpYPV",
      address: new AddressDto(),
      legal: new CustomerLegalInfoDto(),
      corporateIdentity: new CorporateIdentityDto(),
      permissions: [],
      effectivePermissions: [],
    });
  }

  static testValuesFactoryNoneNull() {
    return new UserInfoDto({
      type: "DCIsDaduRQDhyKzXJzYVwGNFFlkYjOAN",
      customerId: "uzRtzKvZkylWudkcEMVhJJPGDsHdRZDY",
      userId: "RhjqSQUONKHbKPYYLHUCOwurhnfLmhLl",
      username: "gkAgfBLkQMYsJcecixmwkUVYtUHWKwnF",
      address: new AddressDto(),
      legal: new CustomerLegalInfoDto(),
      corporateIdentity: new CorporateIdentityDto(),
      permissions: [],
      effectivePermissions: [],
    });
  }
}
