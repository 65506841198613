export default class UpdatePortalUserGroupAssignment {
  userIds: Array<string> = [];

  constructor(
    updatePortalUserGroupAssignmentPart: Partial<UpdatePortalUserGroupAssignment> = {},
  ) {
    Object.assign(this, updatePortalUserGroupAssignmentPart);
  }

  static testValuesFactoryAllNull() {
    return new UpdatePortalUserGroupAssignment({
      userIds: [],
    });
  }

  static testValuesFactorySomeNull() {
    return new UpdatePortalUserGroupAssignment({
      userIds: [],
    });
  }

  static testValuesFactoryNoneNull() {
    return new UpdatePortalUserGroupAssignment({
      userIds: [],
    });
  }
}
