import type StoreDetailBillCreationInformationDto from "~/models/StoreDetailBillCreationInformationDto";
import type StoreOpeningHoursDto from "~/models/StoreOpeningHoursDto";
import AddressDto from "~/models/AddressDto";
import DtoObject from "~/models/DtoObject";

export default class StoreDetailDto extends DtoObject<StoreDetailDto> {
  id: string = "";
  displayName: string | null = null;
  legalName: string | null = null;
  address: AddressDto = new AddressDto();
  email: string | null = null;
  vatId: string | null = null;
  googlePlacesId: string | null = null;
  phoneNumber: string | null = null;
  supportPhoneNumber: string | null = null;
  posCount: number = 0;
  validFrom: string | null = null;
  createdAt: string = new Date().toISOString();
  comingSoon: boolean = true;
  isHidden: boolean = false;
  description: string | null = null;
  website: string | null = null;
  iconResource: string | null = null;
  storeOpeningHours: Array<StoreOpeningHoursDto> | null = null;
  billCreationInformation: Array<
        StoreDetailBillCreationInformationDto
    > | null = null;

  language: string | null = null;

  constructor(storeDetailDtoPart: Partial<StoreDetailDto> = {}) {
    super();
    Object.assign(this, storeDetailDtoPart);
  }

  static testValuesFactoryAllNull() {
    return new StoreDetailDto({
      id: "NdMqKCYFxHmirieWPLsTODTsknENTUKE",
      displayName: null,
      legalName: null,
      address: AddressDto.testValuesFactoryNoneNull(),
      email: null,
      vatId: null,
      googlePlacesId: null,
      phoneNumber: null,
      supportPhoneNumber: null,
      posCount: 57533,
      validFrom: "wMLVcsmUUCANsBnoeYqFsFzpikBJrdDP",
      createdAt: "XZzqFPvTzzZNqjfKHnDBRwRipEwuCSEF",
      comingSoon: false,
      isHidden: false,
      description: null,
      website: null,
      iconResource: null,
      storeOpeningHours: [],
      billCreationInformation: null,
      language: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new StoreDetailDto({
      id: "uHjTcLKiJwdLxkLBrnlxKlqLeCXeekgg",
      displayName: "KNMgzVsLhEQtPraIpaEGFWHFJgWJIgnI",
      legalName: "CGbNUEjYApRNbXmmEBrcmhzGhgBEXqHi",
      address: AddressDto.testValuesFactoryNoneNull(),
      email: null,
      vatId: "ZxaAGzasJRCwQpAfHMHDXIWWboENfMxh",
      googlePlacesId: null,
      phoneNumber: null,
      supportPhoneNumber: "RmqYUInsvjotqViNnZrngPtGrVakywkM",
      posCount: 77142,
      validFrom: "BYFAIzfsiyCFjAHMLEcdFHWmqpoRelge",
      createdAt: "ObAhomKHgPobQEjgfGpmKldQrnjxNFQC",
      comingSoon: false,
      isHidden: false,
      description: "ufyEDjwAQbpMzLlfSKzbmJSIaOQiRicz",
      website: "qouABsrZuADgfqHdNcoKbziNLjjMcOfR",
      iconResource: "YgygzqfcPHqBPKSKoLNewnuWWCMrcesz",
      storeOpeningHours: [],
      billCreationInformation: [],
      language: "ZGCUbnNjychcYFlQOMlpgLsvXzxzQQTL",
    });
  }

  static testValuesFactoryNoneNull() {
    return new StoreDetailDto({
      id: "ZZZbjMjOUWdIuREmrCCqrKjJlJCkiuGa",
      displayName: "LmAWCrmMqQwIeeHHRYBsbxUaVUifxFPp",
      legalName: "BbKkcvOtwmTYmcYojjPIxPWkelHoPZFY",
      address: AddressDto.testValuesFactoryNoneNull(),
      email: "test@anybill.de",
      vatId: "PBadYmjEwEnHucZTpCyQTpCoOIsujAzH",
      googlePlacesId: "WBuOgwaAAxZUjHgOequeLQXJhlIHijai",
      phoneNumber: "kJlXwHAbpAIjOQMvzSdjjtuQnSmhRDLv",
      supportPhoneNumber: "iiewKZdGHCFAitYAcIbdZmFcNhbaxUWZ",
      posCount: 12671,
      validFrom: "zuVcwNVwVSINOxmQyPdSbbjMXLhxGvdo",
      createdAt: "uecJPOrtmZuOIUoLYDVjcKpongXZWAmy",
      comingSoon: false,
      isHidden: false,
      description: "sfntZqKbGwytgHAofJYMWKABNdCcivTE",
      website: "IQFcAVozMxAEsokoUvRhLhgCMXAnRZGY",
      iconResource: "zVLgWjYNkrIPyVucziVabFyVWeauZWBo",
      storeOpeningHours: [],
      billCreationInformation: [],
      language: "QSbXGxUfITijnisQcWWGKCFIlVEUKcTE",
    });
  }
}
